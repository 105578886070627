import React from 'react';
import { PrincipalManageStore } from './store';
import { SearchListStructure } from 'egenie-utils';
import { AddPrincipalModal } from './addPrincipalModal';

export default function PrincipalManage() {
  const principalManageStore = new PrincipalManageStore();

  return (
    <>
      <SearchListStructure store={principalManageStore.searchListStore}/>
      <AddPrincipalModal store={principalManageStore.addPrincipalStore}/>
    </>
  );
}
