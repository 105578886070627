export const api = {
  getPrincipalPage: '/api/gallery/rest/clothingAgent/page',
  enableAgent: '/api/gallery/rest/clothingAgent/enable', // 启用主理人
  disableAgent: '/api/gallery/rest/clothingAgent/disable', // 禁用供应商
  createAgent: '/api/gallery/rest/clothingAgent/create', // 新增主理人
  deleteAgent: '/api/gallery/rest/clothingAgent/delete', // 删除主理人
  getAgentInfo: '/api/gallery/rest/clothingAgent/detail',
  modifyAgent: '/api/gallery/rest/clothingAgent/update', // 修改
  openAgent: '/api/gallery/rest/clothingAgent/createAccount', // 开通账号
};
