import React from 'react';
import type { FilterItemOptions } from 'egenie-utils';
import { Space } from 'antd';
import { Permission } from 'egenie-utils';

export const PRINCIPAL_FILTER_ITEMS = (store): FilterItemOptions[] => {
  return [
    {
      type: 'dateRange',
      label: '创建时间',
      field: 'date',
    },
    {
      type: 'input',
      label: '主理人姓名',
      field: 'clothingAgentName',
    },
    {
      type: 'input',
      label: '主理人手机号',
      field: 'clothingAgentPhone',
    },
  ];
};

export const PRINCIPAL_COLUMNS = (store) => {
  return [
    {
      key: 'operate',
      name: '操作',
      width: 180,
      formatter: ({ row }) => {
        return (
          <Space>
            <Permission permissionId="78_44">
              <a
                onClick={() => {
                  store.addPrincipalStore.openAddPrincipal(true, row.clothingAgentId);
                }}
              >
                编辑
              </a>
            </Permission>
            {/* 开通账号后才可启用禁用 */}
            {
              row.tenantId ? (
                <Permission permissionId={row.clothingAgentStatus === 1 ? '78_4' : '78_3'}>
                  <a onClick={() => {
                    store.enableAgent(row.clothingAgentStatus, row.clothingAgentId);
                  }}
                  >
                    {row.clothingAgentStatus === 1 ? '禁用' : '启用'}
                  </a>
                </Permission>
              ) : null
            }
            {/* 未开通账号后才可删除 */}
            {
              row.tenantId === 0 ? (
                <Permission permissionId="78_45">
                  <a
                    onClick={() => {
                      store.deleteAgent(row.clothingAgentId);
                    }}
                  >
                    删除
                  </a>
                </Permission>
              ) : null
            }
            {/* 租户id存在即账号开通 */}
            {
              row.tenantId === 0 ? (
                <Permission permissionId="78_361">
                  <a onClick={() => {
                    store.openAgent(row.clothingAgentId);
                  }}
                  >
                    开通账号
                  </a>

                </Permission>
              ) : null
            }
          </Space>
        );
      },
    },
    {
      key: 'clothingAgentName',
      name: '主理人姓名',
      width: 120,
    },
    {
      key: 'clothingAgentPhone',
      name: '主理人手机号',
      width: 180,
      
    },

    // {
    //   key: 'commissionRate',
    //   name: '佣金比例',
    //   width: 80,
    //   formatter: ({ row }) => {
    //     return `${row.commissionRate}%`;
    //   },
    // },
    {
      key: 'vendorInfoVos',
      name: '绑定供应商',
      width: 600,
      formatter: ({ row }) => {
        return Array.isArray(row.vendorInfoVos) ? row.vendorInfoVos.map((item) => item.tenantName).join(',') : '';
      },
    },
    {
      key: 'createTime',
      name: '创建日期',
    },
  ];
};

export const PROVIDER_COLUMNS = (store) => {
  return [
    {
      key: 'operate',
      name: '操作',
      width: 120,
      formatter: ({ row }) => {
        return (
          <a>
            删除
          </a>
        );
      },
    },
    {
      key: 'posShopNo',
      name: '供应商编码',
      width: 180,
    },
    {
      key: 'posShopName',
      name: '供应商名称',
      width: 220,

    },
  ];
};

