import { observable, action } from 'mobx';
import type { FormInstance } from 'antd';
import React from 'react';
import { message } from 'antd';
import { api } from '../api';
import type { BaseData } from 'egenie-utils';
import { EgGridModel, request } from 'egenie-utils';
import { PROVIDER_COLUMNS } from '../constant';

interface OpenModal{
  (amFlag: true, id): void;
  (amFlag: false): void;
}
export default class AddPrincipalStore {
  constructor(parent) {
    this.parent = parent;
  }

  public parent;

  @observable public visible = false;

  @observable public amFlag = false;

  @observable public loading = false;

  @observable public saveLoading = false;

  public formRef = React.createRef<FormInstance>();

  public id = '';// 主理人id

  @action public openAddPrincipal: OpenModal = (amFlag, id?) => {
    this.amFlag = amFlag;
    this.visible = true;
    if (this.amFlag) {
      this.id = id;
      this.loading = true;
      request<BaseData>({ url: `${api.getAgentInfo}/${id}` }).then((res) => {
        this.formRef.current.setFieldsValue(res.data);
      })
        .finally(() => {
          this.loading = false;
        });
    }
  };

  @action public closeModal = () => {
    this.amFlag = false;
    this.visible = false;
    this.formRef.current.resetFields();
    this.saveLoading = false;
    this.loading = false;
  };

  @action public handleSubmit = () => {
    this.formRef.current.validateFields().then((data) => {
      this.saveLoading = true;

      if (this.amFlag) {
        Object.assign(data, { clothingAgentId: this.id });
      }
      request<BaseData>({
        url: this.amFlag ? api.modifyAgent : api.createAgent,
        method: 'POST',
        data,
      }).then((res) => {
        message.success(this.amFlag ? '编辑成功' : '创建成功');
        this.closeModal();
        this.parent.searchListStore.grid.onQuery();
      })
        .finally(() => {
          this.saveLoading = false;
        });
    });
  };

  public providerGridModel = new EgGridModel({
    columns: PROVIDER_COLUMNS(this),
    rows: [],
    primaryKeyField: 'posShopId',
    showPager: false,
  });
}
