import React from 'react';
import { observer } from 'mobx-react';
import { phoneReg } from 'egenie-common';
import { Modal, Form, Spin, Row, Col, Input, InputNumber, Select, Button } from 'antd';
import styles from './index.less';
import type AddPrincipalStore from './store';

const { Item } = Form;

const FORM_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

export const Title: React.FC<{ text: string; }> = (props) => {
  return (
    <div className={styles.titleLine}>
      <div className={styles.decoStripe}/>
      {props.text}
    </div>
  );
};

export const AddPrincipalModal: React.FC<{ store: AddPrincipalStore; }> = observer(({ store }) => {
  const { visible, loading, formRef, saveLoading, amFlag, closeModal, handleSubmit, providerGridModel } = store;
  return (
    <Modal
      centered
      forceRender
      maskClosable={false}
      okButtonProps={{ loading: saveLoading }}
      onCancel={closeModal}
      onOk={handleSubmit}
      open={visible}
      title={`${amFlag ? '编辑' : '新增'}主理人`}
      width={900}
    >
      <Spin spinning={loading}>
        <Form
          {...FORM_LAYOUT}
          ref={formRef}
        >
          <Title text="基本信息"/>
          <Row>
            <Col span={12}>
              <Item
                label="主理人名称"
                name="clothingAgentName"
                rules={[
                  { required: true },
                  { max: 20 },
                ]}
              >
                <Input/>
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="手机号"
                name="clothingAgentPhone"
                rules={[
                  { required: true },
                  {
                    pattern: phoneReg,
                    message: '请输入正确的手机号',
                  },
                ]}
              >
                <Input/>
              </Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
});
