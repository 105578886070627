import { SearchListModal, request } from 'egenie-utils';
import AddPrincipalStore from './addPrincipalModal/store';
import { PRINCIPAL_COLUMNS, PRINCIPAL_FILTER_ITEMS } from './constant';
import { api } from './api';
import { Modal, message } from 'antd';
import { mapOptions } from 'egenie-common';
import _ from 'lodash';
import type { NormalProgrammeParams, IMainSubStructureModel, PaginationData, BaseData } from 'egenie-utils';

export class PrincipalManageStore {
  constructor() {
    this.searchListStore.grid.onQuery();
  }

  public addPrincipalStore = new AddPrincipalStore(this);

  public programme: Partial<NormalProgrammeParams> = {
    count: 6,
    filterItems: PRINCIPAL_FILTER_ITEMS(this),
  };

  // 启用/禁用
  public enableAgent = (enable: 1 | 2, ids) => {
    Modal.confirm({
      title: `确认${enable === 2 ? '启用' : '禁用'}该主理人吗？`,
      onOk: () => {
        return request<BaseData>({
          url: enable === 2 ? api.enableAgent : api.disableAgent,
          method: 'POST',
          data: { ids },
        }).then((res) => {
          message.success(enable === 2 ? '启用成功' : '禁用成功');
          this.searchListStore.grid.onQuery();
        });
      },
    });
  };

  private gridModel: IMainSubStructureModel = {
    pageId: '78',
    buttons: [
      {
        text: '新增',
        permissionId: '1',
        handleClick: () => {
          this.addPrincipalStore.openAddPrincipal(false);
        },
      },
    ],
    grid: {
      columns: PRINCIPAL_COLUMNS(this).map((el) => {
        return {
          ...el,
          resizable: true,
          sortable: false,
        };
      }),
      showCheckBox: false,
      primaryKeyField: 'clothingAgentId',
      showEmpty: true,
      showSelectedTotal: true,
      showReset: true,
      rowHeight: 56,
    },
    api: {
      onQuery: (params) => {
        const data = _.omit(params, ['filterParams']);
        const filterParams = params.filterParams;
        if (filterParams.date) {
          const startDate = filterParams.date.split(',')[0];
          const endDate = filterParams.date.split(',')[1];
          Object.assign(data, {
            startDate,
            endDate,
          });
          delete filterParams.date;
        }
        Object.assign(data, filterParams);
        return request({
          url: api.getPrincipalPage,
          method: 'POST',
          data,
        });
      },
    },
  };

  public deleteAgent = (ids) => {
    Modal.confirm({
      title: '是否删除该主理人？',
      onOk: () => {
        return request({
          url: api.deleteAgent,
          method: 'POST',
          data: { ids },
        }).then((res) => {
          message.success('删除成功');
          this.searchListStore.grid.onQuery();
        });
      },
    });
  };

  // 开通账号
  public openAgent = (ids) => {
    this.searchListStore.grid.gridModel.loading = true;
    request<BaseData>({
      url: api.openAgent,
      method: 'POST',
      data: { ids },
    }).then((res) => {
      message.success('开通成功');
      this.searchListStore.grid.onQuery();
    })
      .finally(() => {
        this.searchListStore.grid.gridModel.loading = false;
      });
  };

  public searchListStore = new SearchListModal({
    programme: this.programme,
    grid: this.gridModel,
  });
}
